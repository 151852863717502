import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import moment from "moment-timezone";

export default function MarketClose({ timeZone }) {
  const [timeDifference, setTimeDifference] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      const now = moment.tz(timeZone);
      const dayOfWeek = now.day(); // Sunday = 0, Monday = 1, ..., Saturday = 6

      if (dayOfWeek >= 1 && dayOfWeek <= 5) {
        const twoAM = now.clone().startOf("day").hour(2);
        const difference = twoAM.diff(now);
        setTimeDifference({
          days: 0,
          hours: Math.floor(moment.duration(difference).asHours()),
          minutes: moment.duration(difference).minutes(),
          seconds: moment.duration(difference).seconds(),
        });
      } else {
        const nextMonday = now
          .clone()
          .startOf("week")
          .add((8 - now.day()) % 7, "days")
          .hour(2);
        const difference = nextMonday.diff(now);
        setTimeDifference({
          days: Math.floor(moment.duration(difference).asDays()),
          hours: Math.floor(moment.duration(difference).asHours()) % 24,
          minutes: moment.duration(difference).minutes(),
          seconds: moment.duration(difference).seconds(),
        });
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [timeZone]);

  const formattedTimeDifference = `${
    timeDifference.days === 0 ? "" : `${timeDifference.days} days`
  } ${timeDifference.hours === 0 ? "" : `${timeDifference.hours} hours`} ${
    timeDifference.minutes === 0 ? "" : `${timeDifference.minutes} minutes`
  }  ${timeDifference.seconds} seconds`;

  // const [remainingTime, setRemainingTime] = useState(null);
  // useEffect(() => {
  //   let timerId;
  //   if (!isMarketOpen) {
  //     timerId = setInterval(() => {
  //       let currentTime = new Date();
  //       let nextMonday = new Date();
  //       let dubaiTime = moment(Date.now()).tz("Asia/Dubai");
  //       nextMonday.setFullYear(
  //         Number(dubaiTime.format("YYYY")),
  //         Number(dubaiTime.format("MM")) - 1,
  //         Number(dubaiTime.format("D"))
  //       );
  //       currentTime.setFullYear(
  //         Number(dubaiTime.format("YYYY")),
  //         Number(dubaiTime.format("MM")) - 1,
  //         Number(dubaiTime.format("D"))
  //       );
  //       currentTime.setHours(
  //         dubaiTime.format("H"),
  //         dubaiTime.format("mm"),
  //         dubaiTime.format("ss")
  //       );

  //       if (dubaiTime.format("ddd") !== "Mon") {
  //         nextMonday.setDate(
  //           nextMonday.getDate() + ((1 + 7 - nextMonday.getDay()) % 7 || 7)
  //         );
  //       }
  //       nextMonday.setHours(1, 59, 0, 0);
  //       const elapsedTime = nextMonday - currentTime;
  //       if (elapsedTime <= 0) {
  //         clearInterval(timerId);
  //       } else {
  //         setRemainingTime(elapsedTime);
  //       }
  //     }, 1000);
  //   }
  //   return () => clearInterval(timerId); // Clear the timer when the component unmounts or when startTimer becomes false
  // }, [isMarketOpen]);
  // const formatRemainingTime = (time) => {
  //   const seconds = Math.floor((time / 1000) % 60);
  //   const minutes = Math.floor((time / 1000 / 60) % 60);
  //   const hours = Math.floor((time / 1000 / 60 / 60) % 24);
  //   const days = Math.floor(time / 1000 / 60 / 60 / 24);

  //   return `${days}d ${hours}h ${minutes}m ${seconds}s`;
  // };
  return (
    <Box
      sx={{
        position: "fixed",
        top: "0",
        left: "-125px",
        right: "0",
        bottom: "0",
        width: "895px",
        height: "531px",
        overflow: "hidden",
        zIndex: 10000,
      }}
    >
      <div
        style={{
          position: "relative",
          width: "100%",
          height: "10%",
          transform: "rotate(-35deg)",
          display: "flex",
          alignItems: "center",
          backgroundColor: "#FFFFFF",
          color: "#362F22",
          fontSize: "2vw",
          fontWeight: "bold",
          padding: "1vw",
        }}
      >
        <marquee>
          {timeDifference && (
            <p style={{ marginLeft: "90px" }}>
              Market closed! Opens on {formattedTimeDifference}
              {/* {formatRemainingTime(remainingTime)} */}
            </p>
          )}
        </marquee>
      </div>
    </Box>
  );
}
